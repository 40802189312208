#header{
  position: fixed;
  background-color: white;
  width: 100%;
  text-align: center;
  z-index: 6;
  height: 138px;

  #logo-container{
    margin: 1em;
    #logo{
      width: 300px;
    }
  }

  #cal-icon{
    position:absolute;
  }
}